import { createActionGroup, props } from "@ngrx/store";
import { User, UserMinimal } from "../model";

export enum UsersActions {
  USER_LIST_FETCH = "Users fetch",
  USER_LIST_FETCH_SUCCESS = "Users fetch success",
  USER_LIST_FETCH_FAILURE = "Users fetch failure",
  USER_FETCH_BY_ID_SUCCESS = "User fetch by ID Success",
  USER_FETCH_BY_ID_FAILURE = "User fetch by ID Failure",
  USER_UPDATE_BY_ID_SUCCESS = "User update by ID Success",
}

export const UsersApiActions = createActionGroup({
  source: "Users API",
  events: {
    [UsersActions.USER_LIST_FETCH]: props<{ boom: never }>(),
    [UsersActions.USER_LIST_FETCH_SUCCESS]: props<{
      users: Readonly<UserMinimal[]>;
    }>(),
    [UsersActions.USER_FETCH_BY_ID_SUCCESS]: props<{ user: Readonly<User> }>(),
    [UsersActions.USER_UPDATE_BY_ID_SUCCESS]: props<{ user: Readonly<User> }>(),
  },
});

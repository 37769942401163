import { createReducer, on } from "@ngrx/store";
import { EventDetailed } from "../model";
import { EventsApiActions } from "./event.actions";

export const emptyEvents: Record<number, Partial<EventDetailed>> = {};

export const eventsReducer = createReducer(
  emptyEvents,
  on(EventsApiActions.eventsFetchSuccess, (state, effect) => {
    const result = { ...state };
    effect.events.forEach((event) => {
      result[event.id] = event;
    });
    return result;
  }),
  on(EventsApiActions.eventFetchByIdSuccess, (state, effect) => {
    return { ...state, [effect.event.id]: effect.event };
  })
);

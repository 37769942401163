import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AuthState } from "../model";

export const selectAuth = createFeatureSelector<AuthState>("auth");

export const selectToken = createSelector(selectAuth, (state) => state.token);

export const isAuthenticated = createSelector(
  selectToken,
  (token) => token !== null
);

export const selectHeader = createSelector(
  selectAuth,
  (state) => state.decomposedToken?.header
);

export const selectPayload = createSelector(
  selectAuth,
  (state) => state.decomposedToken?.payload
);

export const selectUserProfile = createSelector(
  selectAuth,
  (state) => state.profile
);

import { CommonModule } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { CoreModule } from "src/app/modules/core/core.module";

const nzImports = [NzLayoutModule, NzGridModule];
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  standalone: true,
  imports: [CommonModule, RouterModule, CoreModule, ...nzImports],
})
export class FooterComponent implements OnInit {
  readonly today = new Date();
  showMobileFooterContent = false;
  constructor() {}

  ngOnInit() {
    this.resized(null);
  }

  @HostListener("window:resize", ["$event"])
  resized(e) {
    if (document.body.clientWidth <= 850) {
      this.showMobileFooterContent = true;
    } else {
      this.showMobileFooterContent = false;
    }
  }
}

import { createReducer, on } from "@ngrx/store";
import { UserMinimal } from "../model";
import { UsersApiActions } from "./users.actions";

export const emptyUsers: Record<number, UserMinimal> = {};

export const usersReducer = createReducer(
  emptyUsers,
  on(UsersApiActions.usersFetchSuccess, (state, effect) => {
    const result = { ...state };
    effect.users.forEach((user) => {
      if (!result[user.id]) {
        result[user.id] = user;
      }
    });
    return result;
  }),
  on(UsersApiActions.userFetchByIdSuccess, (state, effect) => {
    const result = { ...state };
    result[effect.user.id] = effect.user;
    return result;
  }),
  on(UsersApiActions.userUpdateByIdSuccess, (state, effect) => {
    const result = { ...state };
    result[effect.user.id] = effect.user;
    return result;
  })
);

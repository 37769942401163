import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, mergeMap, of, switchMap } from "rxjs";
import { SeasonsApiService } from "../seasons-api.service";
import { SeasonsActions } from "./seasons.actions";

@Injectable()
export class SeasonsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly apiService: SeasonsApiService
  ) {}

  seasonFetchById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeasonsActions.seasonFetchById),
      mergeMap((request) =>
        this.apiService
          .getSeasonById(request.seasonId)
          .pipe(
            map((season) => SeasonsActions.seasonFetchByIdSuccess({ season }))
          )
      )
    )
  );

  seasonFetchUserEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SeasonsActions.seasonEntryFetch),
      switchMap((request) =>
        this.apiService.getSeasonEntry(request.seasonId).pipe(
          map((entrant) => SeasonsActions.seasonEntryFetchSuccess({ entrant })),
          catchError(() =>
            of(
              SeasonsActions.seasonEntryFetchFailure({
                seasonId: request.seasonId,
              })
            )
          )
        )
      )
    )
  );
}

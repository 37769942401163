import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { EventPresence } from "src/app/modules/league/models/event-presence";
import { environment } from "src/environments/environment";
import {
  EventDetailed,
  EventResults,
  EventStandings,
  PreqResult,
} from "./model";

@Injectable({
  providedIn: "root",
})
export class EventsRestService {
  constructor(private readonly http: HttpClient) {}

  uploadEventResults(eventId: number, split: number, file: File) {
    const fd = new FormData();
    fd.append("file", file);
    return this.http.post<boolean>(
      `${environment.backendUrl}event/${eventId}/event-results/${split}`,
      fd
    );
  }

  uploadPreqResults(eventId: number, file: File) {
    const fd = new FormData();
    fd.append("file", file);
    return this.http.post<boolean>(
      `${environment.backendUrl}event/${eventId}/preq-results`,
      fd
    );
  }

  getEventById(eventId: number) {
    return this.http
      .get<EventDetailed>(`${environment.backendUrl}event/${eventId}/detailed`)
      .pipe(
        map((event) => {
          if (event.preqStart) event.preqStart = new Date(event.preqStart);
          if (event.preqStop) event.preqStop = new Date(event.preqStop);
          event.sessions.forEach((session) => {
            if (session.dateStart)
              session.dateStart = new Date(session.dateStart);
            if (session.dateStop) session.dateStop = new Date(session.dateStop);
          });
          return event;
        })
      );
  }

  getPreqResults(eventId: number) {
    return this.http.get<PreqResult[]>(
      `${environment.backendUrl}livetiming/preq/${eventId}`
    );
  }

  getEventResults(eventId: number) {
    return this.http.get<EventResults>(
      `${environment.backendUrl}event/${eventId}/session-results`
    );
  }

  getRoundResults(eventId: number) {
    return this.http.get<EventStandings[]>(
      `${environment.backendUrl}event/${eventId}/classifications`
    );
  }

  changeEventPresenceStatus(body: Partial<EventPresence>) {
    return this.http.post<EventPresence>(
      `${environment.backendUrl}event/enroll`,
      body
    );
  }
}

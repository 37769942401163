import { createReducer, on } from "@ngrx/store";
import { SeasonStorage } from "./model";
import { SeasonsActions } from "./seasons.actions";

export const emptySeasons: SeasonStorage = {
  seasons: {},
  standings: {},
  penaltyPoints: {},
  userEntries: {},
};

export const seasonsReducer = createReducer(
  emptySeasons,
  on(SeasonsActions.seasonFetchByIdSuccess, (state, effect) => {
    const store = structuredClone(state);
    store.seasons[effect.season.id] = effect.season;
    return store;
  }),
  on(SeasonsActions.seasonEntryFetchSuccess, (state, effect) => {
    const store = structuredClone(state);
    store.userEntries[effect.entrant.seasonid] = effect.entrant;
    return store;
  }),
  on(SeasonsActions.seasonEntryFetchFailure, (state, effect) => {
    const store = structuredClone(state);
    store.userEntries[effect.seasonId] = null;
    return store;
  })
);

import { UserMinimal } from "../users/model";

export enum TeamMemberStatus {
  PENDING = 0,
  ACTIVE = 1,
  REJECTED = 2,
  LEFT_BY_MEMBER = 3,
  DELETED_BY_MANAGER = 4,
  TEAM_DELETED = 5,
}

export interface Team {
  id: number;
  name: string;
  logo: string;
  backgroundImage: string;
  color: string;
  createdTime: string | Date;
  ownerId?: number;
  owner?: UserMinimal;
  active: boolean;
  members: TeamMemberDTO[];
  filteredMembers?: TeamMemberDTO[];
}

export interface TeamMemberCreate {
  teamid: number;
  userid: number;
}

export interface TeamMemberUpdate extends TeamMemberCreate {
  id: number;
  proposalStatus: TeamMemberStatus;
}

export interface TeamMemberDTO {
  id: number;
  teamid: number;
  proposalStatus: TeamMemberStatus;
  dateStart: Date;
  dateStop: Date;
  user: UserMinimal;
}

export interface UserTeamMembership {
  id: number;
  userid: number;
  proposalStatus: TeamMemberStatus;
  dateStart: Date;
  dateStop: Date;
  team: Team;
}

export interface TeamCreateDTO {
  name: string;
  addOwnerAsDriver: boolean;
}

export interface TeamUpdateDTO {
  id: number;
  name: string;
  color: string;
}

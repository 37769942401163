import { Injectable } from "@angular/core";
import { UsersService } from "@api/users/users.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { filter, first, map, mergeMap, switchMap } from "rxjs";
import { AuthApiActions } from "./auth.actions";
import { isAuthenticated } from "./auth.selectors";

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly usersRestService: UsersService
  ) {}

  userAuthenticationRestore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.userAuthenticationRestore),
      switchMap(() => this.store.select(isAuthenticated).pipe(first())),
      filter(Boolean),
      mergeMap(() =>
        this.usersRestService
          .getUserProfile()
          .pipe(
            map((profile) =>
              AuthApiActions.userFetchProfileSuccess({ profile })
            )
          )
      )
    )
  );
}

<div class="app" *ngIf="appEnabled === true; else wrongOrNoPassword">
  <header [ngClass]="{ 'home-page-header': currentRoute$ | async }">
    <app-header></app-header>
  </header>
  <main>
    <div class="animator" [@fadeAnimation]="prepareRoute()">
      <router-outlet></router-outlet>
    </div>
  </main>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>
<!--
<nz-modal
  class="regulation-confirmation-modal"
  [nzVisible]="!(hasRegulationsAccepted$ | async)"
  nzTitle="Akceptacja regulaminów"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
>
  <ng-container *nzModalContent>
    <p>
      Do dalszego korzystania ze strony wymagane jest zaakceptowanie regulaminu.
    </p>
    <p>
      <a
        target="_blank"
        href="https://static.mpdm.com.pl/porscheesports/documents/REGULAMIN-MPDM-2021-11.08.2021.pdf"
      >
        <button class="btn btn-outlined-yellow">Pobierz PDF</button>
      </a>
    </p>
    <p>
      Akceptując regulamin potwierdzam, że zapoznałem się z polityką prywatności
      zawartą w regulaminie i wyrażam zgodę na przetwarzanie moich danych
      osobowych przez Ragnar Simulator Sp. z o.o. w celu organizacji Mistrzostw
      Polski Digital Motorsport / Porsche Esports Sprint Poland Challenge 2021
    </p>
  </ng-container>
  <div *nzModalFooter>
    <button class="btn btn-outlined-yellow" (click)="handleCancel()">
      Anuluj
    </button>
    <button class="btn btn-blue" (click)="handleOk()">Potwierdź</button>
  </div>
</nz-modal>
-->
<ng-template #wrongOrNoPassword>
  <ng-container *ngIf="appEnabled === false">
    <h1 class="h1">:-(</h1>
  </ng-container>
</ng-template>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'menu-hamburger',
  templateUrl: './menu-hamburger.component.html',
  styleUrls: ['./menu-hamburger.component.scss']
})
export class MenuHamburgerComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {
  }
}

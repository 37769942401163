import { createReducer, on } from "@ngrx/store";
import { AuthState, JWTToken } from "../model";
import { AuthApiActions } from "./auth.actions";

export const emptyAuth: AuthState = {
  token: null,
  decomposedToken: null,
  profile: {
    protests: [],
    events: [],
    stats: null,
  },
};

export const authReducer = createReducer(
  emptyAuth,
  on(AuthApiActions.userAuthenticationRestore, (state, effect) => {
    const result = { ...state };
    const split = effect.token.split(".");
    if (!split[0] || !split[1]) {
      return result;
    }
    const decomposedToken: JWTToken = {
      header: JSON.parse(atob(split[0])),
      payload: JSON.parse(atob(split[1])),
      signature: null,
    };
    const tokenExp = new Date(decomposedToken.payload.exp * 1000);
    if (new Date() < tokenExp) {
      result.token = effect.token;
      result.decomposedToken = decomposedToken;
    }
    return result;
  }),
  on(AuthApiActions.userAuthenticationSuccess, (state, effect) => {
    const result = { ...state };
    const split = effect.response.token.split(".");
    if (!split[0] || !split[1]) {
      return result;
    }
    result.token = effect.response.token;
    result.decomposedToken = {
      header: JSON.parse(atob(split[0])),
      payload: JSON.parse(atob(split[1])),
      signature: null,
    };
    return result;
  }),
  on(AuthApiActions.userFetchProfileSuccess, (state, effect) => {
    const result = structuredClone(state);
    result.profile = effect.profile;
    return result;
  }),
  on(AuthApiActions.updateProtest, (state, effect) => {
    const result = structuredClone(state);
    const protestIndex = result.profile.protests.findIndex(
      (protest) => protest.id === effect.protest.id
    );
    result.profile.protests[protestIndex] = effect.protest;
    return result;
  }),
  on(AuthApiActions.userAuthenticationFailure, () => ({ ...emptyAuth })),
  on(AuthApiActions.userAuthenticationExpired, () => ({ ...emptyAuth })),
  on(AuthApiActions.userLogout, () => ({ ...emptyAuth }))
);

<section class="app-layout">
  <div nz-row class="footer-top-content">
    <div nz-col [nzLg]="{ span: 3, offset: 2 }">
      <h3 class="subsection-heading">Organizator wydarzenia:</h3>
    </div>
    <div nz-col [nzLg]="3">
      <h3 class="subsection-heading">Partner techniczny:</h3>
    </div>
    <div nz-col [nzLg]="7">
      <h3 class="subsection-heading">Partnerzy medialni:</h3>
    </div>
  </div>
  <div nz-row class="footer-bottom-content">
    <div nz-col [nzXs]="24" [nzLg]="{ span: 3, offset: 2 }">
      <div class="logos">
        <a href="https://ragnarsimulator.com"
          ><img
            title="Ragnar Simulator"
            class="ragnar"
            src="assets/images/pescp-2023/logos/ragnar.webp"
        /></a>
      </div>
    </div>
    <div nz-col [nzXs]="24" [nzLg]="3">
      <div class="logos">
        <img src="assets/images/pescp-2023/logos/iracing.webp" />
      </div>
    </div>
    <div nz-col [nzXs]="24" [nzLg]="7">
      <div class="logos">
        <a href="https://www.onet.pl/"><img
          class="onet"
          src="assets/images/pescp-2023/logos/onet.webp"
        /></a>
        <a href="https://przegladsportowy.onet.pl/"><img
          class="przegladsportowy"
          height="26px"
          src="assets/images/pescp-2023/logos/przeglad-sportowy.webp"
        /></a>
        <a href="https://motowizja.pl/"><img
          class="motowizja"
          src="assets/images/pescp-2023/logos/motowizja.webp"
        /></a>
        <a href="https://rallyandrace.pl/"><img
          class="rally-and-race"
          src="assets/images/pescp-2023/logos/rally-and-race.webp"
        /></a>
        <a href="https://swiatwyscigow.pl/"><img
          class="swiatwyscigow"
          src="assets/images/pescp-2023/logos/swiatwyscigow.webp"
        /></a>
      </div>
    </div>
    <div nz-col [nzXs]="24" [nzLg]="{ span: 3, offset: 5 }">
      <div class="buttons">
        <a
          href="https://www.facebook.com/porscheesportspl/"
          title="Facebook"
          class="contact-btn facebook"
        >
          <app-icon-facebook></app-icon-facebook>
        </a>
        <a
          href="https://www.youtube.com/RagnarSimulator"
          title="YouTube"
          class="contact-btn youtube"
        >
          <app-icon-youtube></app-icon-youtube>
        </a>
        <a
          href="https://www.instagram.com/porsche"
          title="Instagram"
          class="contact-btn instagram"
        >
          <app-icon-instagram></app-icon-instagram>
        </a>
      </div>
    </div>
  </div>
  <div nz-row>
    <div
      nz-col
      [nzXs]="{ span: 12, offset: 6 }"
      [nzLg]="{ span: 4, offset: 10 }"
      class="owner"
    >
      Porsche {{ today | date : "yyyy" }}
    </div>
  </div>
  <!--</ng-template>-->
</section>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EventDetailed } from "@api/event/model";
import { environment } from "src/environments/environment";
import {
  Disciplinary,
  DisciplinaryIssuePenalty,
  DisciplinaryResolveDTO,
} from "../disciplinary/model";
import { NewsView } from "../news/model";
import { AdministrationNewsListDTO, NewsCategoryView } from "./model";

const ADMINISTRATION_PREFIX = `${environment.backendUrl}administration/`;
const NEWS_PREFIX = `${environment.backendUrl}news/`;
const ADMINISTRATION_NEWS_PREFIX = `${ADMINISTRATION_PREFIX}news/`;

const ADMINISTRATION_PROTESTS = `${ADMINISTRATION_PREFIX}disciplinary/`;
const ADMINISTRATION_PROTESTS_V2 = `${ADMINISTRATION_PREFIX}v2/disciplinary/`;

@Injectable({
  providedIn: "root",
})
export class AdministrationRestService {
  constructor(private readonly http: HttpClient) {}

  /** === NEWS === */

  getAllNewsCategories() {
    return this.http.get<NewsCategoryView[]>(
      `${ADMINISTRATION_NEWS_PREFIX}categories`
    );
  }

  getAllNewses() {
    return this.http.get<AdministrationNewsListDTO[]>(
      `${ADMINISTRATION_NEWS_PREFIX}`
    );
  }

  getNews(id: number) {
    return this.http.get<NewsView>(`${ADMINISTRATION_NEWS_PREFIX}${id}`);
  }

  sendNewNews(formData: FormData) {
    return this.http.post<boolean>(`${ADMINISTRATION_NEWS_PREFIX}`, formData, {
      reportProgress: true,
    });
  }

  updateNews(newsId: number, formData: FormData) {
    return this.http.put<boolean>(
      `${ADMINISTRATION_NEWS_PREFIX}${newsId}`,
      formData,
      { reportProgress: true }
    );
  }

  deleteNews(id: string) {
    return this.http.delete<unknown>(`${NEWS_PREFIX}${id}`);
  }

  changeNewsVisibility(id: string) {
    return this.http.post<boolean>(`${NEWS_PREFIX}${id}/visibility`, null);
  }

  /** === PROTESTS === */

  getAllProtests() {
    return this.http.get<Disciplinary[]>(`${ADMINISTRATION_PROTESTS_V2}`);
  }

  getProtestById(id: number) {
    return this.http.get<Disciplinary>(`${ADMINISTRATION_PROTESTS_V2}${id}`);
  }

  getResultLineByDisciplinaryId(id: number) {
    return this.http.get<any /*Result*/>(
      `${ADMINISTRATION_PROTESTS}${id}/result`
    );
  }

  resolveDisciplinaryCase(model: DisciplinaryResolveDTO) {
    return this.http.put<Disciplinary>(
      `${ADMINISTRATION_PROTESTS_V2}resolve`,
      model
    );
  }

  changeProtestVisibility(protestId: number, visibility: boolean) {
    return this.http.put<Disciplinary>(
      `${ADMINISTRATION_PROTESTS}${protestId}/visibility?visibility=${visibility}`,
      null
    );
  }

  issuePenalty(penalty: DisciplinaryIssuePenalty) {
    return this.http.post<Disciplinary>(
      `${ADMINISTRATION_PROTESTS_V2}issue-penalty`,
      penalty
    );
  }

  /** === EVENTS === */
  getAllEvents() {
    return this.http.get<Partial<EventDetailed>[]>(
      `${environment.backendUrl}administration/events/page`
    );
  }
}

import { CommonModule } from "@angular/common";
import { NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { CoreModule } from "../core/core.module";
import { TeamCreateComponent } from "./components/team-create/team-create.component";
import { TeamDetailedComponent } from "./components/team-detailed/team-detailed.component";
import { TeamsListComponent } from "./components/teams-list/teams-list.component";
import { TrackComponent } from "./components/track/track.component";
import { TvResultsComponent } from "./components/tv-results/tv-results.component";

const nzImports = [
  NzTabsModule,
  NzTableModule,
  NzToolTipModule,
  NzFormModule,
  NzModalModule,
  NzSelectModule,
  NzUploadModule,
  NzButtonModule,
  NzDividerModule,
  NzLayoutModule,
];

@NgModule({
  declarations: [
    TrackComponent,
    TeamsListComponent,
    TeamCreateComponent,
    TeamDetailedComponent,
    TvResultsComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ...nzImports,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class LeagueModule {}

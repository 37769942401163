import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainPageLayoutComponent } from "./shared/layouts/main-page-layout/main-page-layout.component";

const DEV_ROUTES: Routes = [
  {
    path: "",
    component: MainPageLayoutComponent,
    loadChildren: () =>
      import("./shared/layouts/main-page-layout/main-page-layout.module").then(
        (m) => m.MainPageLayoutModule
      ),
  },
  {
    path: "tv",
    children: [
      {
        path: "preq/:eventId/:page",
        loadChildren: () =>
          import("@pages/tv/tv-preq-results/tv-preq-results.module").then(
            (m) => m.TvPreqResultsModule
          ),
      },
      {
        path: "session/:session/:split/:page",
        loadChildren: () =>
          import("@pages/tv/tv-results/tv-results.module").then(
            (m) => m.TvResultsModule
          ),
      },
      {
        path: "event/:event/:split/:page",
        loadChildren: () =>
          import("@pages/tv/tv-event-results/tv-event-results.module").then(
            (m) => m.TvEventResultsModule
          ),
      },
      {
        path: "season/:season/:type/:page",
        loadChildren: () =>
          import(
            "@pages/tv/tv-season-classifications/tv-season-classifications.module"
          ).then((m) => m.TvSeasonClassificationsModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/",
  },
];

const PROD_ROUTES: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot(DEV_ROUTES, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { RouterModule } from "@angular/router";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzTableModule } from "ng-zorro-antd/table";
import { DriversClassifitaionComponent } from "./drivers-classifitaion.component";

@NgModule({
  declarations: [DriversClassifitaionComponent],
  imports: [
    CommonModule,
    NzTableModule,
    NzLayoutModule,
    NzGridModule,
    NzButtonModule,
    RouterModule,
  ],
  exports: [DriversClassifitaionComponent],
})
export class DriversClassificationModule {}

import { Injectable } from "@angular/core";
import { AdministrationRestService } from "@api/administration/administration-rest.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NzMessageService } from "ng-zorro-antd/message";
import { catchError, map, mergeMap, of, switchMap, tap } from "rxjs";
import { EventsRestService } from "../events-rest.service";
import { EventsSource } from "../model";
import { EventsApiActions } from "./event.actions";

@Injectable()
export class EventsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly restService: EventsRestService,
    private readonly adminRestService: AdministrationRestService,
    private readonly messageService: NzMessageService
  ) {}

  fetchAllEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsApiActions.eventsFetch),
      switchMap((data) =>
        data.source === EventsSource.ADMIN
          ? this.adminRestService.getAllEvents()
          : of(null)
      ),
      map((events) => EventsApiActions.eventsFetchSuccess({ events }))
    )
  );

  eventFetchById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsApiActions.eventFetchById),
      mergeMap((request) =>
        this.restService.getEventById(request.id).pipe(
          map((event) => EventsApiActions.eventFetchByIdSuccess({ event })),
          catchError(() => of(EventsApiActions.eventFetchByIdFailure({})))
        )
      )
    )
  );

  eventFetchByIdFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsApiActions.eventFetchByIdFailure),
      tap(() =>
        this.messageService.error(
          "Nie znaleziono Rundy o podanym identyfikatorze!"
        )
      )
    )
  );
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  DriverStandings,
  PenaltyPointsStandings,
  RemoveSeasonEntrantTeamAssignmentDTO,
  SeasonDetailedView,
  SeasonEligibilityForUser,
  SeasonEligibilityInboundDTO,
  SeasonEntrantDTO,
  SeasonEntrantView,
  SeasonListView,
  SeasonTeamEntrantDTO,
  TeamStandings,
} from "./model";

const BASE_ROUTE_V2 = `${environment.backendUrl}v2/season`;

@Injectable({
  providedIn: "root",
})
export class SeasonsApiService {
  constructor(private readonly http: HttpClient) {}

  getAllSeasons() {
    return this.http.get<SeasonListView[]>(`${BASE_ROUTE_V2}`);
  }

  getSeasonById(seasonId: number) {
    return this.http.get<SeasonDetailedView>(`${BASE_ROUTE_V2}/${seasonId}`);
  }

  getSeasonEntry(seasonId: number) {
    return this.http.get<SeasonEntrantView>(
      `${BASE_ROUTE_V2}/${seasonId}/entry`
    );
  }

  getDriverStandingsBySeasonId(seasonId: number) {
    return this.http.get<DriverStandings[]>(
      `${BASE_ROUTE_V2}/${seasonId}/standings/drivers`
    );
  }

  getTeamStandingsBySeasonId(seasonId: number) {
    return this.http.get<TeamStandings[]>(
      `${BASE_ROUTE_V2}/${seasonId}/standings/teams`
    );
  }

  getPenaltyPointsStandingsBySeasonId(seasonId: number) {
    return this.http.get<PenaltyPointsStandings[]>(
      `${BASE_ROUTE_V2}/${seasonId}/penaltypoints`
    );
  }

  signUpDriverToSeason(body: SeasonEntrantDTO) {
    return this.http.post<unknown>(`${BASE_ROUTE_V2}/enroll/driver`, body);
  }

  signUpTeamToSeason(body: SeasonTeamEntrantDTO) {
    return this.http.post<unknown>(`${BASE_ROUTE_V2}/enroll/team`, body);
  }

  removeDriverAssignmentToTeam(body: RemoveSeasonEntrantTeamAssignmentDTO) {
    return this.http.put<boolean>(`${BASE_ROUTE_V2}/entrant/clear-team`, body);
  }

  validateUserEligibilityToSeason(
    seasonId: number,
    userId: SeasonEligibilityInboundDTO
  ) {
    return this.http.post<SeasonEligibilityForUser>(
      `${BASE_ROUTE_V2}/${seasonId}/validate-eligibility`,
      userId
    );
  }
}

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../models/config';
import { Country } from '../../models/country';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class ConfigService {

  configLoaded: EventEmitter<Config> = new EventEmitter();
  config: Config;
  countries: Country[];

  readonly countries$ = this.http.get<Country[]>(`/assets/countries.json`).pipe(shareReplay());

  constructor(
    private http: HttpClient
  ) {
    this.getConfig().toPromise().then((config) => {
      this.config = config;
    });
  }

  getConfig() {
    return this.http.get<Config>('./assets/config.json').pipe(tap(config => { this.config = config; this.configLoaded.emit(config); }));
  }

  getCountries() {
    return this.http.get<Country[]>('./assets/countries.json');
  }
}

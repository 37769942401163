import { createActionGroup, props } from "@ngrx/store";
import {
  DriverStandings,
  PenaltyPointsStandings,
  SeasonDetailedView,
  SeasonEntrantView,
} from "../model";

export enum SeasonsActionsEnum {
  EMPTY_ACTION = "Empty Action",
  SEASON_FETCH_BY_ID = "Season Fetch By Id",
  SEASON_FETCH_BY_ID_SUCCESS = "Season Fetch By Id Success",
  SEASON_FETCH_BY_ID_FAILURE = "Season Fetch By Id Failure",
  DRIVER_STANDINGS_FETCH_BY_SEASONID = "Driver Standings Fetch By Seasonid",
  DRIVER_STANDINGS_FETCH_BY_SEASONID_SUCCESS = "Driver Standings Fetch By Seasonid Success",
  DRIVER_STANDINGS_FETCH_BY_SEASONID_FAILURE = "Driver Standings Fetch By Seasonid Failure",
  PENALTY_POINTS_FETCH_BY_SEASONID = "Penalty Points Fetch By Seasonid",
  PENALTY_POINTS_FETCH_BY_SEASONID_SUCCESS = "Penalty Points Fetch By Seasonid Success",
  PENALTY_POINTS_FETCH_BY_SEASONID_FAILURE = "Penalty Points Fetch By Seasonid Failure",
  SEASON_ENTRY_FETCH = "Season Entry Fetch",
  SEASON_ENTRY_FETCH_SUCCESS = "Season Entry Fetch Success",
  SEASON_ENTRY_FETCH_FAILURE = "Season Entry Fetch Failure",
}

export const SeasonsActions = createActionGroup({
  source: "Seasons API",
  events: {
    [SeasonsActionsEnum.EMPTY_ACTION]: props<{
      empty?: unknown;
    }>(),
    [SeasonsActionsEnum.SEASON_FETCH_BY_ID]: props<{ seasonId: number }>(),
    [SeasonsActionsEnum.SEASON_FETCH_BY_ID_SUCCESS]: props<{
      season: SeasonDetailedView;
    }>(),
    [SeasonsActionsEnum.SEASON_FETCH_BY_ID_FAILURE]: props<{
      empty?: unknown;
    }>(),
    [SeasonsActionsEnum.SEASON_ENTRY_FETCH]: props<{ seasonId: number }>(),
    [SeasonsActionsEnum.SEASON_ENTRY_FETCH_SUCCESS]: props<{
      entrant: SeasonEntrantView;
    }>(),
    [SeasonsActionsEnum.SEASON_ENTRY_FETCH_FAILURE]: props<{
      seasonId: number;
    }>(),
    [SeasonsActionsEnum.DRIVER_STANDINGS_FETCH_BY_SEASONID]: props<{
      seasonId: number;
    }>(),
    [SeasonsActionsEnum.DRIVER_STANDINGS_FETCH_BY_SEASONID_SUCCESS]: props<{
      season: DriverStandings[];
    }>(),
    [SeasonsActionsEnum.DRIVER_STANDINGS_FETCH_BY_SEASONID_FAILURE]: props<{
      empty?: unknown;
    }>(),
    [SeasonsActionsEnum.PENALTY_POINTS_FETCH_BY_SEASONID]: props<{
      seasonId: number;
    }>(),
    [SeasonsActionsEnum.PENALTY_POINTS_FETCH_BY_SEASONID_SUCCESS]: props<{
      season: PenaltyPointsStandings[];
    }>(),
    [SeasonsActionsEnum.PENALTY_POINTS_FETCH_BY_SEASONID_FAILURE]: props<{
      empty?: unknown;
    }>(),
  },
});

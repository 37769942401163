<ng-container>
  <app-loader *ngIf="!configLoaded"></app-loader>
  <div class="main-container" *ngIf="configLoaded">
    <router-outlet></router-outlet>
  </div>
</ng-container>
<ng-template #prodMode>
  <div class="mobile">
    <img class="coming-soon" [src]="staticUrl + 'porscheesports/coming-soon.jpg'" />
  </div>
</ng-template>
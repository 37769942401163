import { CommonModule } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { Store } from "@ngrx/store";
import { selectUserProfile } from "@shared/services/auth/state/auth.selectors";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { combineLatest } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { TeamMemberStatus } from "src/app/api/teams/model";
import { CoreModule } from "src/app/modules/core/core.module";
import { AuthService } from "src/app/shared/services/auth/auth.service";

@Component({
  selector: "app-header",
  standalone: true,
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    NzBadgeModule,
    NzMenuModule,
  ],
})
export class HeaderComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private router: Router,
    private readonly store: Store
  ) {
    router.events.subscribe((event) => {
      this.openMenu = false;
    });
  }

  steamLoginUrl = "https://steamcommunity.com/openid/login";
  openIDparams: string[] = [
    "openid.identity=http://specs.openid.net/auth/2.0/identifier_select",
    "openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select",
    "openid.ns=http://specs.openid.net/auth/2.0",
    "openid.mode=checkid_setup",
    "openid.realm=http://localhost:4200/home",
    "openid.return_to=http://localhost:4200/home",
  ];
  openMenu: boolean;
  baseLink: string = "./assets/images/static/menu-";
  sufixLink: string = ".png";
  innerWidth: any;

  readonly authenticated$ = this.authService.isAuthenticated$;
  readonly user$ = this.authService.user$;
  readonly userFirstName$ = this.user$.pipe(
    startWith({ firstName: "" }),
    filter((user) => !!user),
    map((user) => user.firstName)
  );
  readonly pendingTeamInvitationsCount$ = this.authService.user$.pipe(
    map((user) =>
      user
        ? user.belongingTeams.filter(
            (membership) =>
              membership.proposalStatus === TeamMemberStatus.PENDING
          ).length
        : 0
    )
  );
  readonly hasAdminPrivileges$ = this.authService.user$.pipe(
    map((user) => (user ? user.permissions.length > 0 : false))
  );
  readonly pendingProtests$ = combineLatest([
    this.user$,
    this.store.select(selectUserProfile),
  ]).pipe(
    filter(([user, profile]) => user && profile?.protests?.length > 0),
    map(
      ([user, profile]) =>
        profile.protests.filter(
          (protest) =>
            protest.defendant.id === user.id &&
            !protest.defendantDescription &&
            !protest.issuedBySteward &&
            protest.visible &&
            new Date(
              new Date(protest.createdDate).getTime() + 24 * 60 * 60 * 1000
            ) > new Date()
        ).length
    )
  );

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.openMenu = false;
    this.onResize(null);
  }

  auth() {
    const params = "?" + this.openIDparams.join("&");
    const url = this.steamLoginUrl + params;
    window.location.href = url;
  }

  logout() {
    this.authService.logout();
  }

  clickHamburger() {
    this.openMenu = !this.openMenu;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}

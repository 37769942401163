import { createActionGroup, props } from "@ngrx/store";
import { EventDetailed, EventsSource } from "../model";

export enum EventsActions {
  EVENTS_FETCH = "Events fetch",
  EVENTS_FETCH_SUCCESS = "Events fetch success",
  EVENT_FETCH_BY_ID = "Event fetch by id",
  EVENT_FETCH_BY_ID_SUCCESS = "event fetch by id success",
  EVENT_FETCH_BY_ID_FAILURE = "event fetch by id failure",
  EVENT_PRESENCE_FETCH = "Event presence fetch",
  EVENT_PRESENCE_FETCH_SUCCESS = "Event presence fetch success",
  EVENT_PRESENCE_FETCH_FAILURE = "Event presence fetch failure",
}

export const EventsApiActions = createActionGroup({
  source: "Events API",
  events: {
    [EventsActions.EVENTS_FETCH]: props<{ source?: EventsSource }>(),
    [EventsActions.EVENTS_FETCH_SUCCESS]: props<{
      events: Partial<EventDetailed>[];
    }>(),
    [EventsActions.EVENT_FETCH_BY_ID]: props<{ id: number }>(),
    [EventsActions.EVENT_FETCH_BY_ID_SUCCESS]: props<{
      event: Partial<EventDetailed>;
    }>(),
    [EventsActions.EVENT_FETCH_BY_ID_FAILURE]: props<{ param?: unknown }>(),
  },
});

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  ChangePasswordDTO,
  PorscheUserHistories,
  ResetPasswordDTO,
  ResetPasswordRequestDTO,
  User,
  UserDashboard,
  UserMinimal,
  UserSignInDTO,
} from "./model";

const USERS_PREFIX = `${environment.backendUrl}users/`;
const USERS_V2_PREFIX = `${environment.backendUrl}v2/users/`;

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Get all users from database in minified form (for dropdowns for example)
   * @returns UserMinimal[]
   */
  getUsers() {
    return this.http.get<Array<UserMinimal>>(`${USERS_PREFIX}`);
  }

  /**
   * Fetches specific user. In case it's the user currently logged in, fetches full user data. Otherwise UserMinimal
   * @param id
   * @returns User or UserMinimal
   */
  getUserById(id: number) {
    return this.http.get<User | UserMinimal>(`${USERS_PREFIX}${id}`);
  }

  /**
   * Get user dashboard profile
   * @returns
   */
  getUserProfile() {
    return this.http.get<UserDashboard>(`${USERS_V2_PREFIX}profile`);
  }

  /**
   * Endpoint for creating new user;
   * @param user User object
   * @returns user email as string
   */
  createUser(user: User) {
    return this.http.post<string>(`${USERS_PREFIX}add`, user);
  }

  /**
   * Endpoint for handling authenticated user updates
   * @param user Updated user
   * @returns ??
   */
  updateUser(user: User, avatar?: File) {
    const fd = new FormData();
    const blob = new Blob([JSON.stringify(user)], { type: "application/json" });
    fd.append("user", blob);
    if (avatar) {
      fd.append("avatar", avatar);
    }
    return this.http.put<User>(`${USERS_PREFIX}`, fd, {
      reportProgress: true,
    });
  }

  /**
   * Sends request to send email with activation link
   * @returns boolean if the email has been sent
   */
  verifyEmailRequest() {
    return this.http.post<boolean>(`${USERS_PREFIX}verify-email`, null);
  }

  /**
   * Completes the process of confirming user email address
   * @param token token from email
   * @returns boolean if the request was valid
   */
  confirmEmail(token: string) {
    return this.http.post<boolean>(`${USERS_PREFIX}confirm-email`, token);
  }

  /**
   * Authorization
   * @param model UserSignInDTO
   * @returns token object
   */
  signin(model: UserSignInDTO) {
    return this.http.post<{ token: string }>(`${USERS_PREFIX}signin`, model);
  }

  /**
   * Change password for authenticated user
   * @param model ChangePasswordDTO
   * @returns HTTP Response
   */
  changePassword(model: ChangePasswordDTO) {
    return this.http.put<unknown>(`${USERS_PREFIX}changepassword`, model);
  }

  /**
   * Endpoint for validation if token is acceptable by backend
   * @returns
   */
  validateToken() {
    return this.http.post<boolean>(`${USERS_PREFIX}validate-auth`, null);
  }

  /**
   * Called when user knows his email address but can't remember password
   * @param model PasswordResetRequestDTO
   * @returns HTTP Response
   */
  forgotPassword(model: ResetPasswordRequestDTO) {
    return this.http.post<unknown>(`${USERS_PREFIX}forgot-password`, model);
  }

  /**
   * Updates user password after forgotPassword method execution
   * @param model ResetPasswordDTO
   * @returns HTTP Response
   */
  resetPassword(model: ResetPasswordDTO) {
    return this.http.post<unknown>(`${USERS_PREFIX}password-reset`, model);
  }

  /**
   * Created exclusively for PESCP interview with drivers
   * @param userid
   * @returns PorscheUserHistories
   */
  getUserHistoryById(userid: number) {
    return this.http.get<PorscheUserHistories>(
      `${USERS_PREFIX}${userid}/history`
    );
  }
}

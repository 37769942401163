import { HttpErrorResponse } from "@angular/common/http";
import { Disciplinary } from "@api/disciplinary/model";
import { UserDashboard } from "@api/users/model";
import { createActionGroup, props } from "@ngrx/store";
import { UserAuthenticationResponseDTO } from "../model";

export enum AuthActions {
  USER_AUTHENTICATION_RESTORE = "User authentication restore",
  USER_AUTHENTICATON_SUCCESS = "User authentication success",
  USER_AUTHENTICATION_FAILURE = "User authentication failure",
  USER_AUTHENTICATION_EXPIRED = "User authentication expired",
  USER_LOGOUT = "User logout",
  USER_FETCH_PROFILE = "User Fetch profile",
  USER_FETCH_PROFILE_SUCCESS = "user fetch profile success",
  USER_FETCH_PROFILE_FAILURE = "user fetch profile failure",
  UPDATE_PROTEST = "Update protest",
}

export const AuthApiActions = createActionGroup({
  source: "Auth API",
  events: {
    [AuthActions.USER_AUTHENTICATION_RESTORE]: props<{ token: string }>(),
    [AuthActions.USER_AUTHENTICATON_SUCCESS]: props<{
      response: Readonly<UserAuthenticationResponseDTO>;
    }>(),
    [AuthActions.USER_AUTHENTICATION_FAILURE]: props<{
      response: Readonly<HttpErrorResponse>;
    }>(),
    [AuthActions.USER_AUTHENTICATION_EXPIRED]: props<{ date?: Date }>(),
    [AuthActions.USER_LOGOUT]: props<{ source?: any }>(),
    [AuthActions.USER_FETCH_PROFILE]: props<{ empty?: unknown }>(),
    [AuthActions.USER_FETCH_PROFILE_SUCCESS]: props<{
      profile: UserDashboard;
    }>(),
    [AuthActions.USER_FETCH_PROFILE_FAILURE]: props<{ empty?: unknown }>(),
    [AuthActions.UPDATE_PROTEST]: props<{ protest: Disciplinary }>(),
  },
});

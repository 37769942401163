<div class="app-layout">
  <nav>
    <div
      class="d-flex flex-row flex-wrap align-items-center justify-content-between my-3 menu"
      [ngClass]="openMenu ? 'responsive' : 'mb-3'"
    >
      <div class="logo" [routerLink]="'/home'">
        <img src="/assets/images/pescp-2022/logo_pescp_white.webp" />
      </div>
      <div
        class="icon d-flex justify-content-end d-lg-none"
        (click)="clickHamburger()"
      >
        <menu-hamburger *ngIf="!openMenu"></menu-hamburger>
        <menu-cross *ngIf="openMenu"></menu-cross>
      </div>
      <ul
        class="flex-column flex-xl-row align-items-xl-center flex-grow-1 justify-content-center py-lg-3 mt-3 mt-lg-0 m-0 p-0"
        [ngClass]="openMenu ? 'd-flex' : 'd-none d-xl-flex'"
      >
        <li class="menu-item px-lg-3">
          <a routerLink="/news" routerLinkActive="active">News</a>
        </li>
        <li class="menu-item px-lg-3">
          <a routerLink="/how-to" routerLinkActive="active">Jak zacząć</a>
        </li>
        <li class="menu-item px-lg-3">
          <nz-badge
            [nzCount]="pendingTeamInvitationsCount$ | async"
            [nzOverflowCount]="9"
            nzSize="small"
          >
            <a routerLink="/teams" routerLinkActive="active">Zespoły</a>
          </nz-badge>
        </li>
        <li class="menu-item px-lg-3">
          <a routerLink="/championship" routerLinkActive="active">
            Mistrzostwa
          </a>
        </li>
        <li *ngIf="authenticated$ | async" class="menu-item px-lg-3">
          <nz-badge
            [nzCount]="pendingProtests$ | async"
            [nzOverflowCount]="9"
            nzSize="small"
          >
            <a routerLink="/protests" routerLinkActive="active">Protesty</a>
          </nz-badge>
        </li>
        <li class="menu-item px-lg-3">
          <a routerLink="/calendar" routerLinkActive="active">Kalendarz</a>
        </li>
        <li class="menu-item px-lg-3">
          <a routerLink="/archive" routerLinkActive="active">Archiwum</a>
        </li>
        <li class="menu-item px-lg-3">
          <a routerLink="/media" routerLinkActive="active">Media</a>
        </li>
        <li class="menu-item px-lg-3">
          <a routerLink="/contact" routerLinkActive="active">Kontakt</a>
        </li>
        <li class="menu-item px-lg-3" *ngIf="hasAdminPrivileges$ | async">
          <a routerLink="/administration" routerLinkActive="active">Admin</a>
        </li>
      </ul>
      <ng-container *ngIf="innerWidth > 960">
        <ul nz-menu nzMode="horizontal">
          <li
            *ngIf="!(authenticated$ | async)"
            nz-submenu
            nzMatchRouter
            nzTitle="Twoje konto"
          >
            <ul>
              <li nz-menu-item nzMatchRouter routerLink="/sign-in">
                Zaloguj się
              </li>
              <li nz-menu-item nzMatchRouter routerLink="/sign-up">
                Zarejestruj się
              </li>
            </ul>
          </li>
          <li
            *ngIf="authenticated$ | async"
            nz-submenu
            [nzTitle]="'Witaj, ' + (userFirstName$ | async)"
          >
            <ul>
              <li nz-menu-item nzMatchRouter routerLink="/settings">
                Ustawienia konta
              </li>
              <li nz-menu-item nzMatchRouter (click)="logout()">Wyloguj się</li>
            </ul>
          </li>
        </ul>
      </ng-container>
      <div
        *ngIf="innerWidth <= 960"
        [ngClass]="openMenu ? 'd-flex w-100' : 'd-none d-xl-flex'"
        class="buttons flex-column my-3"
      >
        <ng-container *ngIf="!(authenticated$ | async)">
          <a class="big-link" [routerLink]="'/sign-in'">Zaloguj się</a>
          <a class="big-link" [routerLink]="'/sign-up'">Zarejestruj się</a>
        </ng-container>
        <ng-container *ngIf="authenticated$ | async">
          <a class="big-link" [routerLink]="'/settings'">Ustawienia konta</a>
          <a class="big-link" (click)="logout()">Wyloguj się</a>
        </ng-container>
      </div>
    </div>
  </nav>
</div>

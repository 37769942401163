import { createFeatureSelector, createSelector } from "@ngrx/store";
import { User, UserMinimal } from "../model";

export const selectUsers = createFeatureSelector<{
  [id: number]: User | UserMinimal;
}>("users");

export const selectAllUsers = createSelector(selectUsers, (users) => [
  ...new Set(Object.values(users)),
]);

export const selectUserById = (id) =>
  createSelector(selectUsers, (users) => users[id]);

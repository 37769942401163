import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { DriversClassificationModule } from "src/app/drivers-classification/drivers-classification.module";
import { AtComponent } from "./components/icons/at/at.component";
import { CarComponent } from "./components/icons/car/car.component";
import { CupComponent } from "./components/icons/cup/cup.component";
import { DiscordComponent } from "./components/icons/discord/discord.component";
import { FacebookComponent } from "./components/icons/facebook/facebook.component";
import { GooglePlusComponent } from "./components/icons/google-plus/google-plus.component";
import { InstagramComponent } from "./components/icons/instagram/instagram.component";
import { LinkedinComponent } from "./components/icons/linkedin/linkedin.component";
import { MenuCrossComponent } from "./components/icons/menu-cross/menu-cross.component";
import { MenuHamburgerComponent } from "./components/icons/menu-hamburger/menu-hamburger.component";
import { PinterestComponent } from "./components/icons/pinterest/pinterest.component";
import { RaceTrackComponent } from "./components/icons/race-track/race-track.component";
import { SteamComponent } from "./components/icons/steam/steam.component";
import { TwitterComponent } from "./components/icons/twitter/twitter.component";
import { YoutubeComponent } from "./components/icons/youtube/youtube.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { MpdmDestroyComponent } from "./components/mpdm-destroy/mpdm-destroy.component";
import { PasswordResetComponent } from "./components/password-reset/password-reset.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { TabComponent } from "./components/tab/tab.component";
import { TabsComponent } from "./components/tabs/tabs.component";
import { CarouselItemDirective } from "./directives/carousel-item.directive";
import { FloorPipe } from "./pipes/floor.pipe";

@NgModule({
  declarations: [
    SignUpComponent,
    LoaderComponent,
    SteamComponent,
    DiscordComponent,
    CarComponent,
    RaceTrackComponent,
    CupComponent,
    CarouselItemDirective,
    InstagramComponent,
    FacebookComponent,
    GooglePlusComponent,
    YoutubeComponent,
    TwitterComponent,
    PinterestComponent,
    TabsComponent,
    TabComponent,
    AtComponent,
    PasswordResetComponent,
    MenuHamburgerComponent,
    MenuCrossComponent,
    MpdmDestroyComponent,
    FloorPipe,
    LinkedinComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzProgressModule,
    NzUploadModule,
    NzGridModule,
    NzFormModule,
    RouterModule,
    DriversClassificationModule,
    NzModalModule,
    NzInputModule,
    NzCheckboxModule,
    NzButtonModule,
    NzSpinModule,
  ],
  exports: [
    LoaderComponent,
    SteamComponent,
    DiscordComponent,
    CarComponent,
    RaceTrackComponent,
    CupComponent,
    InstagramComponent,
    FacebookComponent,
    GooglePlusComponent,
    YoutubeComponent,
    TwitterComponent,
    PinterestComponent,
    TabsComponent,
    TabComponent,
    AtComponent,
    MpdmDestroyComponent,
    FloorPipe,
    LinkedinComponent,
    MenuHamburgerComponent,
    MenuCrossComponent,
  ],
  providers: [NzMessageService],
  schemas: [],
})
export class CoreModule {}
